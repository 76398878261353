import { FormKitTypeDefinition } from '@formkit/core'
import {
  outer,
  inner,
  wrapper,
  label,
  help,
  messages,
  message,
  icon,
  prefix,
  suffix,
  textInput,
  casts,
} from '../compose'

/**
 * Input definition for a text.
 * @public
 */
export const text: FormKitTypeDefinition = {
  /**
   * The actual schema of the input, or a function that returns the schema.
   */
  schema: /* #__PURE__ */ outer(
    /* #__PURE__ */ wrapper(
      /* #__PURE__ */ label('$label'),
      /* #__PURE__ */ inner(
        /* #__PURE__ */ icon('prefix', 'label'),
        /* #__PURE__ */ prefix(),
        /* #__PURE__ */ textInput(),
        /* #__PURE__ */ suffix(),
        /* #__PURE__ */ icon('suffix')
      )
    ),
    /* #__PURE__ */ help('$help'),
    /* #__PURE__ */ messages(/* #__PURE__ */ message('$message.value'))
  ),
  /**
   * The type of node, can be a list, group, or input.
   */
  type: 'input',
  /**
   * The family of inputs this one belongs too. For example "text" and "email"
   * are both part of the "text" family. This is primary used for styling.
   */
  family: 'text',
  /**
   * An array of extra props to accept for this input.
   */
  props: [],
  /**
   * Additional features that should be added to your input
   */
  features: [casts],
  /**
   * The key used to memoize the schema.
   */
  schemaMemoKey: 'c3cc4kflsg',
}
